import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, TrailguidePlugin } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`To speak of one destination is a bit undersized. The over 80 km long valley
offers endless possibilities and ends at the highest mountain of the alps,
the Mont Blanc.`}</p>
    <br />
    <p>{`Aosta, with its medieval center, is the heart of the valley
to where trails descent up to 2000vm. You will find high alpine terrain with
technical trails, but also a lot of flow along the ancient water channels
called “Ru”.`}</p>
    <br />
    <p>{`With the bike park Pila and shuttle services you can also save
some energy for more descents. Best time to go is from May to October.`}</p>
    <TrailguidePlugin content="lat=45.7309&lng=7.2663" mdxType="TrailguidePlugin" />
    <p className="text-xs mb-16" align="center">
      <p>{`Open these trails directly in the `}<a parentName="p" {...{
          "href": "https://trailguide.net/?pos=12,45.7309,7.2663"
        }}>{`web-app`}</a></p>
    </p>
    <h4>{`Saint Nicolas`}</h4>
    <p>{`Dusty corners on the south faced mountains.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/2387"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/aosta_1.jpg" mdxType="Image" />
    <br />
    <h4>{`Saint Nicolas`}</h4>
    <p>{`You come through little mountain villages everywhere in the valley.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/2387"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/aosta_2.jpg" mdxType="Image" />
    <br />
    <h4>{`Becca Trail`}</h4>
    <p>{`The mountains around the valley are high and you can get very long descents
up to 2000vm.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/2389"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/aosta_7.jpg" mdxType="Image" />
    <br />
    <h4>{`Becca Trail`}</h4>
    <p>{`The terrain higher up is wide open and the trails are fast.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/2389"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/aosta_8.jpg" mdxType="Image" />
    <br />
    <h4>{`Ru de Vens`}</h4>
    <p>{`Along the whole valley there is an endless amount of old water channels
called -Ru-. They slightly descend and you will mostly find a very flowy
trail next to it.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/2388"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/aosta_3.jpg" mdxType="Image" />
    <br />
    <h4>{`Ru de Vens`}</h4>
    <p>{`Great flow in the woods.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/2388"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/aosta_4.jpg" mdxType="Image" />
    <br />
    <h4>{`Enjoy`}</h4>
    <p>{`Don't forget to stop and enjoy the great Italian food.`}</p>
    <Image src="destinations/aosta_6.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      